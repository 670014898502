






































































































































































































import { Component, Vue, Watch, ModelSync } from 'vue-property-decorator'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  Table,
  TableHeader,
  TableCell,
  TableRow
} from 'tiptap-extensions'
import TextBold24 from '@carbon/icons-vue/es/text--bold/24'
import TextItalic24 from '@carbon/icons-vue/es/text--italic/24'
import TextStrikethrough24 from '@carbon/icons-vue/es/text--strikethrough/24'
import TextUnderline24 from '@carbon/icons-vue/es/text--underline/24'
import Paragraph24 from '@carbon/icons-vue/es/paragraph/24'
import ListBulleted24 from '@carbon/icons-vue/es/list--bulleted/24'
import ListNumbered24 from '@carbon/icons-vue/es/list--numbered/24'
import Quotes24 from '@carbon/icons-vue/es/quotes/24'
import Row24 from '@carbon/icons-vue/es/row/24'
import DataTable24 from '@carbon/icons-vue/es/data-table/24'

@Component({
  components: {
    TextBold24,
    TextItalic24,
    TextStrikethrough24,
    TextUnderline24,
    Paragraph24,
    ListBulleted24,
    ListNumbered24,
    Quotes24,
    Row24,
    DataTable24,
    Table,
    TableHeader,
    TableCell,
    TableRow,
    EditorContent,
    EditorMenuBar
  }
})
export default class RichTextEditor extends Vue {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  textEditor = new Editor({})

  linkUrl: string | null = ''
  linkMenuIsActive = false

  $refs!: {
    linkInput: any;
  }

  created () {
    this.textEditor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new Table({
          resizable: true
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow()
      ],
      content: this.inputField
    })
  }

  @Watch('textEditor', { immediate: true })
  onEditorChange () {
    this.textEditor.on('update', () => {
      this.inputField = this.textEditor.getHTML()
    })
  }

  @Watch('inputField', { immediate: true })
  onInputChange (value: string) {
    const html = this.textEditor.getHTML()
    if (html !== value) {
      this.textEditor.setContent(value)
    }
  }

  showLinkMenu (attrs: { href: string }) {
    this.linkUrl = attrs.href
    this.linkMenuIsActive = true
    this.$nextTick(() => {
      this.$refs.linkInput.focus()
    })
  }

  hideLinkMenu () {
    this.linkUrl = null
    this.linkMenuIsActive = false
  }

  setLinkUrl (command: Function, url: string) {
    command({ href: url })
    this.hideLinkMenu()
  }

  beforeDestroy () {
    this.textEditor.destroy()
  }
}
